import { format, distanceInWords, differenceInDays } from 'date-fns';
import React from 'react';
import { buildImageObj } from '../../../lib/helpers';
import { imageUrlFor } from '../../../lib/image-url';
import PortableText from '../portableText';
import Container from '../../container';
import AuthorList from '../author-list/author-list';

import styles from './blog-post.module.css';
import BlogPostPreview from '../blog-post-preview/blog-post-preview';
import ContactSection from '../../layout/ContactSection/contactSession';

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, posts } = props;
  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do, YYYY')}
              </div>
            )}
            {authors && <AuthorList items={authors} title='Authors' />}
            {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
      {posts.length ? (
        <Container>
          <ul className={styles.bottomGrid}>
            {posts.map((post) => (
              <li key={post.node.id}>
                <BlogPostPreview {...post.node} />
              </li>
            ))}
          </ul>
        </Container>
      ) : null}
      <ContactSection link='/assets/images/single_blog_contact.png' />
    </article>
  );
}

export default BlogPost;
